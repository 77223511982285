<template>
  <div class="order_refund" :style="{ marginTop: isWxApplets ? '46px' : '' }">
    <template v-if="isWxApplets">
      <van-nav-bar
        title="申请退款"
        fixed
        left-arrow
        @click-left="onClickLeft"
      />
    </template>
    <!-- 商品信息 -->
    <div class="order_info">
      <div class="order_title">退款商品</div>
      <div class="info_detail">
        <img src="../../../assets/ShopImg/t.jpg" alt="" />
        <div class="detail_content">
          <div>旭苁抽屉画架画板套装样木木制便 携式油画架素描水粉写生美术</div>
          <div><span>150*60com</span></div>
          <div><span>￥388</span><span>×1</span></div>
        </div>
      </div>
    </div>
    <!-- 退款信息 -->
    <div class="refund_info">
      <div class="info_title">退款信息</div>
      <div class="info_tab">
        <div>货物状态<span>*</span></div>
        <div>请选择></div>
      </div>
      <div class="info_tab">
        <div>退款原因<span>*</span></div>
        <div>请选择></div>
      </div>
      <div class="info_tab" style="margin-bottom: 0">
        <div>退款金额<span>*</span></div>
        <div class="tab_money"><span>￥</span><span>388</span></div>
      </div>
    </div>
    <!-- 补充描述和凭证 -->
    <div class="refund_text">
      <div class="text_title">退款信息</div>
      <div class="text_content">
        <textarea
          placeholder="补充描述，有助于商家更好的处理售后问题"
        ></textarea>
        <div class="text_upload">
          <div></div>
          <div>上传凭证</div>
        </div>
      </div>
    </div>
    <div class="refund_btn" @click="gotorefundDetail">提交</div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isWxApplets: true,
    };
  },
  methods: {
    //前去退款详情
    gotorefundDetail() {
      this.$router.push({
        path: "/shop/orders/refundDetail",
      });
    },
    onClickLeft() {
      window.history.go(-1);
    },
  },
  created() {
    //判断是否是微信浏览器的函数
    var ua = window.navigator.userAgent.toLowerCase();
    if (ua.match(/MicroMessenger/i) == "micromessenger") {
      document.title = "申请退款";
      this.isWxApplets = false;
    }
  },
};
</script>

<style lang="scss" scoped>
/deep/ .van-ellipsis {
  color: #333333;
  font-size: 1rem;
  font-weight: 700;
}
/deep/ .van-nav-bar .van-icon {
  color: #333333;
}
//导航栏下边框
/deep/ .van-hairline--bottom::after {
  border: 0;
}
.order_refund {
  padding: 0.9375rem 0.9375rem 0;
  display: flex;
  align-items: center;
  flex-direction: column;
  .order_info {
    background: #fff;
    width: 100%;
    // height: 12.6rem;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    border-radius: 0.375rem;
    .order_title {
      font-size: 0.875rem;
      color: #333333;
      padding-bottom: 0.9375rem;
      font-weight: 700;
    }
    .info_detail {
      display: flex;
      img {
        width: 5.5625rem;
        height: 5.5rem;
        border-radius: 0.25rem;
        margin-right: 0.9375rem;
      }
      .detail_content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        div:first-child {
          font-size: 0.875rem;
          color: #333333;
          font-weight: 700;
        }
        div:nth-child(2) {
          font-size: 0.75rem;
          color: #858585;
          display: flex;
          justify-content: space-between;
        }
        div:nth-child(3) {
          display: flex;
          justify-content: space-between;
          align-items: center;
          font-size: 0.75rem;
          color: #fe4a4a;
          span {
            color: #fe4a4a;
            font-size: 0.9375rem;
            font-weight: 700;
          }
          span:nth-child(2) {
            font-size: 0.75rem;
            color: #858585;
            font-weight: 400;
          }
        }
      }
    }
  }
  .refund_info {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    border-radius: 0.375rem;
    margin-top: 0.9375rem;
    .info_title {
      font-size: 0.875rem;
      color: #333333;
      padding-bottom: 0.9375rem;
      font-weight: 700;
    }
    .info_tab {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 0.9375rem;
      font-size: 0.8125rem;
      color: #333;
      div:first-child {
        span {
          color: #fe4a4a;
        }
      }
      div:nth-child(2) {
        color: #b8b7b7;
      }
      .tab_money {
        color: #fe4a4a !important;
        span:first-child {
          font-size: 0.625rem !important;
        }
        span:last-child {
          font-size: 1rem !important;
          font-weight: 700;
        }
      }
    }
  }
  .refund_text {
    background: #fff;
    width: 100%;
    box-sizing: border-box;
    padding: 0.9375rem;
    position: relative;
    border-radius: 0.375rem;
    margin-top: 0.9375rem;
    .text_title {
      font-size: 0.875rem;
      color: #333333;
      padding-bottom: 0.9375rem;
      font-weight: 700;
    }
    .text_content {
      width: 100%;
      height: 11.25rem;
      background: #f6f6f6;
      textarea {
        width: 100%;
        height: 50%;
        border: none;
        background: #f6f6f6;
      }
      .text_upload {
        width: 5rem;
        height: 4.5rem;
        border: 1px dashed #d8d7d7;
        display: flex;
        flex-direction: column;
        justify-content: space-evenly;
        align-items: center;
        margin-left: 0.75rem;
        margin-bottom: 0.75rem;
        border-radius: 0.25rem;
        div:first-child {
          width: 1.375rem;
          height: 1.125rem;
          border: 1px solid #a4a4a4;
        }
        div:last-child {
          font-size: 0.625rem;
          color: #999999;
        }
      }
    }
  }
  .refund_btn {
    width: 17.5rem;
    height: 2.625rem;
    background: linear-gradient(to right, #fc7134, #f54024);
    color: #ffffff;
    font-size: 0.9375rem;
    text-align: center;
    line-height: 2.625rem;
    margin-top: 4.25rem;
    border-radius: 1.875rem;
  }
}
/deep/ .van-nav-bar .van-icon {
  color: #000;
  font-size: 20px;
}
</style>
